import React from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import Heading from "./Heading";
import { useTranslation } from "react-i18next";

const FqaForm = () => {
  const { t } = useTranslation();

  return (
    <section className="fqa__form__sec">
      <Container className="form__sec">
        <div className="form__con">
          <div>
            <Heading title="" subtitle={t("common.form.subtitle_1")} />
          </div>
          <Form>
            <Row>
              <Col lg={12} className="col-12 mb-3">
                <FloatingLabel
                  controlId="floatingText"
                  label={t("common.form.name")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("common.form.name")}
                  />
                </FloatingLabel>
              </Col>
              <Col lg={12} className="col-12">
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("common.form.phone")}
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder={t("common.form.phone")}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel
              controlId="floatingTextarea2"
              label={t("common.form.message")}
            >
              <Form.Control
                as="textarea"
                placeholder={t("common.form.message")}
                style={{ height: "100px" }}
              />
            </FloatingLabel>
            <div className="d-flex justify-content-center mt-5">
              <Button className="find__now">
                <div>
                  <span className="transition" />
                  <span className="label">{t("common.send")}</span>
                </div>
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default FqaForm;
