import React, { useEffect, useRef, useState } from "react";
import "../../sass/tourscss/tour.scss";
import "../../sass/pagebanner.scss";
import { tourcat, destination } from "../../data/category";
import {
  Accordion,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import PageBanner from "../../components/PageBanner";
import Slider from "@mui/material/Slider";
import Drop from "../../components/Drop";
import { tour } from "../../data/tour";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

const Tour = () => {
  const { t } = useTranslation();

  const afterdiscount = (item) => {
    const discountAmount = (item.discount / 100) * item.price;
    return item.price - discountAmount;
  };

  return (
    <section className="tour__page">
      <PageBanner title={t("menu.tours")} />
      <section className="tour__body">
        <Container>
          <Row className="my-5">
            {tour.map((item, index) => (
              <Col lg={6} md={6} className="mb-3">
                <div className="tour__right">
                  <Row key={index} className="card__con p-3">
                    <Col lg={6} className="left__card">
                      <img className="w-100" src={item.thumbnail} />
                    </Col>
                    <Col lg={6} className="right__card">
                      <h2>{item.name}</h2>
                      {item.discount ? (
                        <p>
                          From ${afterdiscount(item)} / <del>${item.price}</del>
                        </p>
                      ) : (
                        <p>${item.price}</p>
                      )}
                      <div>
                        <h4>Free Package Facility</h4>
                        <ul>
                          {item.included.map((item, index) => (
                            <li key={index}>
                              <i className="bi bi-check-lg" />
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <LinkContainer to={`/tour/${item.id}`}>
                        <Button className="find__now mt-4">
                          <div>
                            <span className="transition" />
                            <span className="label">Explore Now</span>
                          </div>
                        </Button>
                      </LinkContainer>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </section>
  );
};

export default Tour;
