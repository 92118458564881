import React from "react";
import "../sass/hero.scss";
import { Button, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <section className="hero__sec">
      {/* <Container> */}
      <div className="banner__plane">
        <img src="/assets/images/banner-plane.svg" />
      </div>
      <img className="cloud__one" src="/assets/images/cloud1.svg" />
      <img className="cloud__two" src="/assets/images/cloud2.svg" />
      <div className="left__hero">
        <img src="/assets/images/banner-1.png" />
      </div>
      <div className="content">
        <p>{t("common.hero.text_1")}</p>
        <h1>{t("common.hero.text_2")}</h1>
        <p>{t("common.hero.text_3")}</p>
        <Button>
          <LinkContainer to={"/tour"}>
            <div>
              <span className="transition" />
              <span className="label">{t("common.hero.btn")}</span>
            </div>
          </LinkContainer>
        </Button>
      </div>
      <div className="right__hero">
        <img src="/assets/images/banner-2.png" />
        <img className="round" src="/assets/images/banner-inner-img.jpg" />
      </div>

      {/* </Container> */}
    </section>
  );
};

export default Hero;
