import React from "react";
import "../sass/topheader.scss";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TopHeader = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <section className="top__header__sec d-none d-xl-block">
      <Container>
        <Row className="align-items-center">
          <Col xl={10} className="d-flex gap-5  align-items-center">
            <div className="d-flex gap-2  align-items-center">
              <i className="bi bi-stopwatch"></i>
              <p>{t("work_time")}</p>
            </div>
            <div className="d-flex gap-2  align-items-center">
              <i className="bi bi-geo-alt"></i>
              <p>{t("location")}</p>
            </div>
          </Col>
          <Col xl={2} className="d-flex justify-content-end">
            {t("lang") == "ru" ? (
              <div onClick={() => changeLanguage("en")}>Английский</div>
            ) : (
              <div onClick={() => changeLanguage("ru")}>Russian</div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TopHeader;
