import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";

const Parralax = () => {
  const { t } = useTranslation();

  return (
    <div className="parallax">
      <div className="overlay">
        <Container className="text__con">
          <div className="text ">
            <p>{t("common.home.parralax.text_1")}</p>
            <h4>{t("common.home.parralax.text_2")}</h4>
            <p>{t("common.home.parralax.text_3")}</p>
            {/* <LinkContainer to={"/tour"}>
              <Button className="find__now ">
                <div>
                  <span className="transition" />
                  <span className="label">Find Now</span>
                </div>
              </Button>
            </LinkContainer> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Parralax;
