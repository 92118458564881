export const destination = [
    {
        'id': 1,
        'name': 'Hill City, poland',
        'title':"Visiting Poland Popular Place",
        "category" : 'adventure',
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-4.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 2,
        'name': 'Sudan',
        "category": "city",
        'title':"Visiting America president building(white house)",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-5.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 3,
        'name': 'Ethiopia!',
        "category": "cruises",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-7.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 4,
        'name': 'South America',
        "category": "sea",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-7.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 5,
        'name': 'Middle East',
        "category": "travel",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-8.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 6,
        'name': 'Paris, Frnace',
        "category": "wedding",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-6.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 7,
        'name': 'Caribbean',
        "category": "wedding",
        'title':"Visiting Poland Popular Place",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-4.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 8,
        'name': 'Aegean',
        "category": "travel",
        'title':"Visiting America president building(white house)",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-5.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 9,
        'name': 'Hill City, poland',
        "category": "sea",
        'title':"Visiting Poland Popular Place",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-4.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 10,
        'name': 'Sudan',
        "category": "cruises",
        'title':"Visiting America president building(white house)",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-5.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 11,
        'name': 'Ethiopia!',
        "category": "city",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-7.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 12,
        'name': 'South America',
        "category": "adventure",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-7.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 13,
        'name': 'Middle East',
        "category": "city",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-8.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 14,
        'name': 'Paris, Frnace',
        "category": "cruises",
        'title':"Visiting Pyramids, Egypt Desert",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-6.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    },
    {
        'id': 15,
        'name': 'Caribbean',
        "category": "sea",
        'title':"Visiting Poland Popular Place",
        "description": [
            "One of the most famous landmarks in the world, the Eiffel Tower (la Tour Eiffel) symbolizes Paris. Before arriving here, visitors have a vivid image of this monument as seen in a postcard, movie, or on a keychain, yet the Eiffel Tower still manages to amaze.",
            "This masterpiece of architectural achievement soars to a height of 324 meters. A feat of ingenuity, the structure of 18,000 iron parts is held together by 2.5 million rivets.",
            "Despite its massive weight, the Eiffel Tower has an airy lattice construction with the seeming lightness of a ballerina on point. The graceful tower is also known as the “Iron Lady.” In 1887, the Journal Officiel launched a competition for a tower to be exhibited at the fair. Entrepreneur Gustave Eiffel, along with architect Stephen Sauvestre and collaborating engineers, submitted the winning project.",
            "In the evening, the Eiffel Tower is among the most magical places to visit in Paris. The landmark is at its most enchanting at night when it is illuminated with Golden Lighting that glows from lamps placed inside the tower’s framework. The effect is truly magical. The Golden Lighting was created by electrician and engineer Pierre Bideau in 1985.",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ],
        'note':'Note: Some businesses may be temporarily closed due to recent global health and safety issues.',
        'thumbnail': 'https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/destination-4.jpg',
        'images':[
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details3-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details2-1.png",
            "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/destination-details1.png"
        ],
        "details":[
            "Read the basic info and also some fun facts about Eiffel Tower! Here you will find everything you should know about the ‘’Land of Plenty’’!",
            "The esplanade at the base of the Eiffel Tower is dominated by four massive pillars, which support the entire weight of the 10,100-ton monument. Gustave Eiffel’s engineers Emile Nouguier and Maurice Koechlin worked on mathematical principles to ensure stability."
        ]

    }
    
]