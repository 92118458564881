import React, { useState } from "react";
import "../../sass/about/about.scss";
import PageBanner from "../../components/PageBanner";
import { Button, Col, Container, Row } from "react-bootstrap";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const [countStart, setCountStart] = useState(false);

  return (
    <section className="about__page">
      <PageBanner title={t("menu.about")} />
      <section className="py-5 my-5">
        <Container>
          <Row>
            <Col lg={6} className="text d-flex flex-column gap-4">
              <p>
                Jasmin Travel Agency – это ваше окно в мир незабываемых
                путешествий и приключений. Мы предоставляем широкий спектр
                туристических услуг, чтобы сделать ваш отдых комфортным,
                интересным и безопасным. Наша команда профессионалов с
                многолетним опытом работы всегда готова помочь вам спланировать
                идеальное путешествие.
              </p>
              <ul>
                <li>
                  Индивидуальный подход: Мы учитываем все ваши пожелания и
                  предпочтения, чтобы создать для вас идеальное путешествие.
                </li>
                <li>
                  Конкурентоспособные цены: Мы предлагаем лучшие цены на все
                  наши услуги и гибкую систему скидок для постоянных клиентов.
                </li>
                <li>
                  Партнерство с ведущими компаниями: Мы сотрудничаем с ведущими
                  авиакомпаниями, гостиничными сетями и туроператорами, чтобы
                  предложить вам самые выгодные условия.
                </li>
              </ul>
            </Col>
            <Col lg={6} className="d-none d-lg-block">
              <Row>
                <Col lg={6} className="img__con1">
                  <div className="one">
                    <img
                      className="w-100 "
                      src="https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/feature3.png"
                    />
                  </div>
                </Col>
                <Col lg={6} className="img__con2">
                  <div className="two">
                    <img
                      className="w-100"
                      src="https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/feature4.png"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row>
          <Col lg={12}>
            <h3 className="text-center">{t("certificate")}</h3>
            <div>
              <img
                src="./assets/images/certificate.jpg"
                alt="certificate"
                style={{
                  transform: "rotate(-90deg)",
                  width: "100%",
                  height: "auto",
                  position: "relative",
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <section className="counter__sec mb-5">
        <Container>
          <ScrollTrigger
            onEnter={() => setCountStart(true)}
            onExit={() => setCountStart(false)}
          >
            <Row className="counter__con">
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-globe-asia-australia"></i>
                <h2>
                  {countStart && <CountUp end={400} duration={5} suffix="+" />}
                </h2>
                <p>Awesome Tour</p>
              </Col>
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-geo-alt-fill"></i>
                <h2>
                  {countStart && <CountUp end={250} duration={5} suffix="+" />}
                </h2>
                <p>Stunning Places</p>
              </Col>
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-award"></i>
                <h2>
                  {countStart && <CountUp end={650} duration={5} suffix="+" />}
                </h2>
                <p>Satisfied Customer</p>
              </Col>
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-people-fill"></i>
                <h2>
                  {countStart && <CountUp end={500} duration={5} suffix="+" />}
                </h2>
                <p>Travel Guides</p>
              </Col>
            </Row>
          </ScrollTrigger>
        </Container>
      </section>
    </section>
  );
};

export default About;
