import React from "react";
import "../sass/footer.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="footer__sec pt-5">
      <Container className="pt-5 pb-4">
        <Row className="mb-5">
          <Col
            lg={3}
            sm={12}
            className="circle__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center"
          >
            <div className="footer__circle">
              <img src="/assets/images/logo.svg" />
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="footer__info__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center"
          >
            <div>
              <p className="footer__title">{t("common.info")}</p>
              <ul>
                <li>
                  <Link to="/tour">{t("menu.tours")}</Link>
                </li>
                <li>
                  <Link to="/about">{t("menu.about")}</Link>
                </li>
                <li>
                  <Link to="/blog">{t("menu.blog")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("menu.contact")}</Link>
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col
            lg={3}
            sm={6}
            className="footer__info__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center"
          >
            <div>
              <p className="footer__title">Destination</p>
              <ul>
                {capitalizedcat.map((item, index) => (
                  <li key={index}>
                    <a>{item}</a>
                  </li>
                ))}
              </ul>
            </div>
          </Col> */}
          <Col
            lg={3}
            sm={12}
            className="footer__info__con mb-5 mb-lg-0 justify-content-start justify-content-lg-center"
          >
            <div>
              <p className="footer__title">{t("common.connect_with_us")}</p>
              <ul>
                <li className="d-flex gap-3 align-items-center">
                  <i className="bi bi-telephone"></i>
                  <div className="d-flex flex-column gap-2">
                    <a>+998 (97) 702 04 80</a>
                  </div>
                </li>
                <li className="d-flex gap-3 align-items-center">
                  <i className="bi bi-envelope"></i>
                  <div className="d-flex flex-column gap-2">
                    <a>Jasmintour.uz@gmail.com</a>
                  </div>
                </li>
                <li className="d-flex gap-3 align-items-center">
                  <i className="bi bi-geo-alt"></i>
                  <div className="d-flex flex-column gap-2">
                    <a>{t("location")}</a>
                  </div>
                </li>
                <li className="d-flex gap-3 align-items-center">
                  <i className="bi bi-stopwatch"></i>
                  <div className="d-flex flex-column gap-2">
                    <a>{t("work_time")}</a>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="py-4 mb-5">
          <Col
            lg={6}
            className="col-12 footer__middle d-flex gap-4 align-items-center mb-5 mb-xl-0"
          >
            <p>{t("common.stay_connected")}:</p>
            <ul className="d-flex align-items-center gap-4 flex-wrap">
              <li>
                <a>
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li>
                <a>
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li>
                <a>
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className=" footer__low__con pt-4">
          <Col lg={6} className="footer__lower mb-2 mb-lg-0">
            <p>
              Copyright 2024 <span>Jasmin Travel</span>
              {/* | Design By{" "}<span>Bipul Magar</span> */}
            </p>
          </Col>
          <Col
            lg={6}
            className="footer__lower d-flex justify-content-lg-end justify-content-start"
          >
            <ul className="d-flex gap-2">
              <li>{t("common.direktor")}</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
