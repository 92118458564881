import React, { useState } from "react";
import "../../sass/contact/contact.scss";
import PageBanner from "../../components/PageBanner";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Heading from "../../components/Heading";
import { useTranslation } from "react-i18next";
import {
  FullscreenControl,
  Map,
  Placemark,
  Polygon,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";

const Contact = () => {
  const { t } = useTranslation();
  const [defaultMapLocation, setDefaultMapLocation] = useState({
    latitude: 41.31065,
    longitude: 69.279608,
  });

  return (
    <section className="contact__page">
      <PageBanner title={t("menu.contact")} />
      <Container className="d-flex justify-content-center align-items-center py-5 mt-5">
        <div className="text__con text-center">
          <h3>Jasmin Travel Agency</h3>
          <p>{t("common.contact.text_1")}</p>
        </div>
      </Container>
      <Container className="mb-5 pb-5">
        <Row>
          <Col lg={4}>
            <div className="short__info">
              <span>
                <i className="bi bi-geo-alt"></i>
              </span>
              <div className="text">
                <h4>{t("common.address")}</h4>
                <p>{t("location")}</p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="short__info">
              <span>
                <i className="bi bi-telephone-outbound"></i>
              </span>
              <div className="text">
                <h4>{t("common.call_us")}</h4>
                <p>+998 (97) 702 04 80</p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="short__info">
              <span>
                <i className="bi bi-envelope-at"></i>
              </span>
              <div className="text">
                <h4>{t("common.info_by_email")}</h4>
                <p>Jasmintour.uz@gmail.com</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Heading title={t("common.form.title")} subtitle={t("common.form.subtitle")} />
        <div className="form__con px-0 px-lg-5 ">
          <Form>
            <Form.Group className="col-lg-12" controlId="formGridText1">
              <Form.Control type="text" placeholder={t("common.form.name")} />
            </Form.Group>
            <Form.Group className="col-lg-12" controlId="formGridEmail">
              <Form.Control type="text" placeholder={t("common.form.phone")} />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={t("common.form.message")}
              />
            </Form.Group>
            <div className="d-flex justify-content-center align-items-center mb-5">
              <Button className="find__now ">
                <div>
                  <span className="transition" />
                  <span className="label">{t("common.send")}</span>
                </div>
              </Button>
            </div>
          </Form>
        </div>
      </Container>
      <YMaps
        query={{
          apikey: "5605e773-07b2-4ea2-845b-7e5be23d18294",
          lang: "ru_RU",
          load: "package.full",
        }}
      >
        <Map
          // onClick={(e) => mapOnClick(e)}
          defaultState={{
            center: [defaultMapLocation.latitude, defaultMapLocation.longitude],
            zoom: 15,
            controls: [],
          }}
          options={{
            suppressMapOpenBlock: true,
          }}
          style={{ width: "100%", height: "86vh" }}
        >
          <Polygon />
          <FullscreenControl />
          <ZoomControl />

          <Placemark
            geometry={[
              defaultMapLocation.latitude,
              defaultMapLocation.longitude,
            ]}
            options={{
              preset: "islands#icon",
            }}
          />
        </Map>
      </YMaps>
    </section>
  );
};

export default Contact;
