import React, { useState } from "react";
import "../sass/home.scss";
import { Col, Container, Row } from "react-bootstrap";
import Hero from "../components/Hero";
import Filter from "../components/Filter";
import PopularTour from "../components/PopularTour";
import DealHome from "../components/DealHome";
import Heading from "../components/Heading";
import DestinationHome from "../components/DestinationHome";
import FqaForm from "../components/FqaForm";
import Parralax from "../components/Parralax";
import TourSlide from "../components/TourSlide";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import BlogHome from "../components/BlogHome";
import Partners from "../components/Partners";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  const [countStart, setCountStart] = useState(false);
  return (
    <section className="home__sec">
      <Hero />
      {/* <Filter /> */}
      <Heading
        title={t("common.home.text_1")}
        subtitle={t("common.home.text_2")}
      />
      <PopularTour />
      <Heading
        title={t("common.home.text_3")}
        subtitle={t("common.home.text_4")}
      />
      <DealHome />
      {/* <Heading title="МЕСТО НАЗНАЧЕНИЯ" subtitle="Желаемое место назначения" />
      <DestinationHome /> */}
      <Parralax />
      <FqaForm />
      <Heading
        title={t("common.home.text_5")}
        subtitle={t("common.home.text_6")}
      />
      <TourSlide />
      <section className="counter__sec">
        <Container>
          <ScrollTrigger
            onEnter={() => setCountStart(true)}
            onExit={() => setCountStart(false)}
          >
            <Row className="counter__con">
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-globe-asia-australia"></i>
                <h2>
                  {countStart && <CountUp end={400} duration={5} suffix="+" />}
                </h2>
                <p>{t("common.awesome_tour")}</p>
              </Col>
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-geo-alt-fill"></i>
                <h2>
                  {countStart && <CountUp end={250} duration={5} suffix="+" />}
                </h2>
                <p>{t("common.stunning_places")}</p>
              </Col>
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-award"></i>
                <h2>
                  {countStart && <CountUp end={650} duration={5} suffix="+" />}
                </h2>
                <p>{t("common.satisfied_customer")}</p>
              </Col>
              <Col
                lg={3}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <i className="bi bi-people-fill"></i>
                <h2>
                  {countStart && <CountUp end={500} duration={5} suffix="+" />}
                </h2>
                <p>{t("common.travel_guides")}</p>
              </Col>
            </Row>
          </ScrollTrigger>
        </Container>
      </section>
      <Heading
        title={t("common.home.text_7")}
        subtitle={t("common.home.text_8")}
      />
      <BlogHome />
      {/* <Heading
        title="JASMIN TRAVEL AGENCY ПАРТНЕРЫ"
        subtitle="Наши самые надежные партнеры"
      />
      <Partners /> */}
    </section>
  );
};

export default Home;
