import React, { useState } from "react";
import "../sass/header.scss";
import {
  Col,
  Container,
  Dropdown,
  NavLink,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { tourcat } from "../data/category";
import { LinkContainer } from "react-router-bootstrap";
import { tour } from "../data/tour";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [fix, setFix] = useState(false);
  const [selectedTravel, setSelectedTravel] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const capitalizedtour = tourcat.map(
    (cat) => cat.charAt(0).toUpperCase() + cat.slice(1)
  );

  const stayFixed = () => {
    if (window.scrollY >= 300) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  window.addEventListener("scroll", stayFixed);

  const handleTravel = (e) => {
    setSelectedTravel(e.target.innerText);
    const filteredTour = tour.filter((item) => {
      return item.category.some((cat) =>
        cat.toLowerCase().includes(selectedTravel.toLowerCase())
      );
    });

    navigate("/findfilter", {
      state: { filteredTour: filteredTour, type: selectedTravel },
    });
  };

  return (
    <header className={`${fix ? "header__sec fixed__top" : "header__sec"}`}>
      <Container>
        <Row className="align-items-center">
          <Col xl={2} className="col-2">
            <LinkContainer to={"/"}>
              <img src="/assets/images/logo.svg" />
            </LinkContainer>
          </Col>
          <Col
            xl={8}
            className="col-10 d-flex justify-content-end justify-content-xl-center gap-5 align-items-center"
          >
            <ul className=" menu d-none d-lg-flex justify-content-end justify-content-xl-center gap-4 align-items-center">
              <li>
                <LinkContainer to={"/"}>
                  <NavLink>{t("menu.home")}</NavLink>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to={"/tour"}>
                  <NavLink>{t("menu.tours")}</NavLink>
                </LinkContainer>
              </li>
              {/* <li>
                <NavLink className="d-flex gap-2 align-items-center">
                  Tour
                  <i className="bi bi-chevron-down" />
                </NavLink>
                <div className="tour__box">
                  <div className="white" />
                  <div className="sub__con">
                    <ul className="sub__menu">
                      <LinkContainer to={"/tour"}>
                        <li>
                          <a>All Tours</a>
                        </li>
                      </LinkContainer>
                      {capitalizedtour.map((item, index) => (
                        <li key={index}>
                          <Dropdown.Item onClick={(e) => handleTravel(e)}>
                            {item}
                          </Dropdown.Item>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li> */}
              {/* <li>
                <LinkContainer to={"/destination"}>
                  <NavLink className="d-flex gap-2 align-items-center">
                    Destination
                  </NavLink>
                </LinkContainer>
              </li> */}
              <li>
                <LinkContainer to={"/about"}>
                  <a>{t("menu.about")}</a>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to={"/blogs"}>
                  <NavLink className="d-flex gap-2 align-items-center">
                    {t("menu.blog")}
                  </NavLink>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to={"/contact"}>
                  <a>{t("menu.contact")}</a>
                </LinkContainer>
              </li>
            </ul>
            {t("lang") == "ru" ? (
              <div
                className="d-lg-none d-block"
                onClick={() => changeLanguage("en")}
              >
                Английский
              </div>
            ) : (
              <div
                className="d-lg-none d-block"
                onClick={() => changeLanguage("ru")}
              >
                Russian
              </div>
            )}
            <a onClick={handleShow}>
              <i
                className="bi bi-list d-lg-none d-block"
                style={{ fontSize: 30 }}
              />
            </a>
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <LinkContainer to={"/"} style={{ height: 70 }}>
                  <img src="/assets/images/logo.svg" style={{ height: 70 }} />
                </LinkContainer>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-flex justify-content-between flex-column">
                <ul className="top">
                  <li className="mb-3">
                    <LinkContainer to={"/"}>
                      <NavLink>{t("menu.home")}</NavLink>
                    </LinkContainer>
                  </li>
                  <li className="mb-3">
                    <LinkContainer to={"/tour"}>
                      <NavLink className="d-flex gap-2 align-items-center">
                        {t("menu.tours")}
                      </NavLink>
                    </LinkContainer>
                  </li>
                  <li className="mb-3">
                    <LinkContainer to={"/blogs"}>
                      <NavLink className="d-flex gap-2 align-items-center">
                        {t("menu.blog")}
                      </NavLink>
                    </LinkContainer>
                  </li>
                  <li className="mb-3">
                    <LinkContainer to={"/about"}>
                      <NavLink> {t("menu.about")}</NavLink>
                    </LinkContainer>
                  </li>
                  <li className="mb-3">
                    <LinkContainer to={"/contact"}>
                      <NavLink> {t("menu.contact")}</NavLink>
                    </LinkContainer>
                  </li>
                  <li className="call d-flex gap-2 align-items-center mb-4">
                    <i className="bi bi-telephone" />
                    <div>
                      <p>{t("common.call_now")}</p>
                      <p>+998 (97) 702 04 80</p>
                    </div>
                  </li>
                </ul>
                <ul className="foot">
                  <li>
                    <div className="d-flex gap-2  align-items-center">
                      <i className="bi bi-stopwatch" />
                      <p>{t("work_time")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex gap-2  align-items-center">
                      <i className="bi bi-geo-alt" />
                      <p>{t("location")}</p>
                    </div>
                  </li>
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col
            xl={2}
            className="d-none d-xl-flex gap-2 justify-content-end align-items-center"
          >
            <i className="bi bi-telephone" />
            <div>
              <p>{t("common.call_now")}</p>
              <p>+998 (97) 702 04 80</p>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
