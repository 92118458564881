import React from "react";
import "../sass/populartour.scss";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PopularTour = () => {
  const { t } = useTranslation();

  return (
    <section className="popular__tour">
      <Container>
        <div className="content">
          <Row className="">
            <Col xl={2} md={6} className="col__con">
              <div className="inner__con">
                <div className="svg__con">
                  <img src="/assets/images/adventure.svg" />
                </div>
                <p>{t("common.home.type_1")}</p>
              </div>
            </Col>
            <Col xl={2} md={6} className="col__con">
              <div className="inner__con">
                <div className="svg__con">
                  <img src="/assets/images/city-tour.svg" />
                </div>
                <p>{t("common.home.type_2")}</p>
              </div>
            </Col>
            <Col xl={2} md={6} className="col__con">
              <div className="inner__con">
                <div className="svg__con">
                  <img src="/assets/images/sheep.svg" />
                </div>
                <p>{t("common.home.type_3")}</p>
              </div>
            </Col>
            <Col xl={2} md={6} className="col__con">
              <div className="inner__con">
                <div className="svg__con">
                  <img src="/assets/images/sea-tour.svg" />
                </div>
                <p>{t("common.home.type_4")}</p>
              </div>
            </Col>
            <Col xl={2} md={6} className="col__con">
              <div className="inner__con">
                <div className="svg__con">
                  <img src="/assets/images/travel.svg" />
                </div>
                <p>{t("common.home.type_5")}</p>
              </div>
            </Col>
            <Col xl={2} md={6} className="col__con">
              <div className="inner__con">
                <div className="svg__con">
                  <img src="/assets/images/wedding.svg" />
                </div>
                <p>{t("common.home.type_6")}</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default PopularTour;
