export const tourRu = [
  {
    id: 1,
    name: "Южная Америка",
    place: "Южная Америка",
    category: ["приключение", "город", "путешествие"],
    price: 768,
    discount: null,
    children: 100,
    duration: {
      days: 7,
      night: 8,
    },
    month: {
      checkin: "1 января 2023",
      checkout: "8 января 2023",
    },
    max: 15,
    description: [
      "Флавиев амфитеатр, более известный как Колизей, является самым универсально признанным символом Рима. Вы можете исследовать эту популярную туристическую достопримечательность самостоятельно или воспользоваться услугами гида для получения дополнительной информации и возможности задавать вопросы по ходу.",
      "Несмотря на повреждения от пожара, землетрясений и запустения, а также его превращение в крепость семьи Франджипани и разграбление камня для строительства дворцов, Колизей остается крупнейшим сохранившимся сооружением древнего Рима. Его строительство было начато Веспасианом в 72 году н.э., а восемь лет спустя его сын Тит добавил четвертый этаж. Название Колизей произошло от гигантской статуи Нерона, называемой колоссом после Колосса",
    ],
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/tour-pack-4-425x345.jpg",
    images: [
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img2.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img3.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img4.png",
    ],
    Destination: "Южная Америка",
    departureandreturn: "Международный аэропорт имени Р.Ф.К. в Риме",
    departuretime:
      "Пожалуйста, прибудьте к 9:15 утра для отправления в 9:30 утра",
    returntime: "Ориентировочно в 10:30 вечера",
    included: [
      "Бесплатная лучшая экскурсия с гидом",
      "Бесплатные сообщения",
      "Без платы за бронирование",
      "Гарантия лучшей цены",
    ],
    excludes: [
      "Дополнительные услуги",
      "Страховка",
      "Транспорт",
      "Входные билеты",
      "Любые частные расходы",
    ],
  },
  {
    id: 2,
    name: "Париж, Франция",
    place: "Париж, Франция",
    category: ["круизы", "море", "путешествие"],
    price: 589,
    discount: 16,
    children: 100,
    duration: {
      days: 8,
      night: 9,
    },
    month: {
      checkin: "1 февраля 2023",
      checkout: "8 февраля 2023",
    },
    max: 45,
    description: [
      "Флавиев амфитеатр, более известный как Колизей, является самым универсально признанным символом Рима. Вы можете исследовать эту популярную туристическую достопримечательность самостоятельно или воспользоваться услугами гида для получения дополнительной информации и возможности задавать вопросы по ходу.",
      "Несмотря на повреждения от пожара, землетрясений и запустения, а также его превращение в крепость семьи Франджипани и разграбление камня для строительства дворцов, Колизей остается крупнейшим сохранившимся сооружением древнего Рима. Его строительство было начато Веспасианом в 72 году н.э., а восемь лет спустя его сын Тит добавил четвертый этаж. Название Колизей произошло от гигантской статуи Нерона, называемой колоссом после Колосса",
    ],
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/tour-pack-9-425x345.jpg",
    images: [
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img2.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img3.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img4.png",
    ],
    Destination: "Эгейское приключение",
    departureandreturn: "Международный аэропорт имени Кэт. Мэн",
    departuretime:
      "Пожалуйста, прибудьте к 9:15 утра для отправления в 9:30 утра",
    returntime: "Ориентировочно в 10:30 вечера",
    included: [
      "Бесплатная лучшая экскурсия с гидом",
      "Бесплатные сообщения",
      "Без платы за бронирование",
      "Гарантия лучшей цены",
    ],
    excludes: [
      "Дополнительные услуги",
      "Страховка",
      "Транспорт",
      "Входные билеты",
      "Любые частные расходы",
    ],
  },
  {
    id: 3,
    name: "Хилл-Сити, Польша",
    place: "Хилл-Сити, Польша",
    category: ["приключение", "город", "круизы", "море", "путешествие"],
    price: 695,
    discount: null,
    children: 100,
    duration: {
      days: 12,
      night: 13,
    },
    month: {
      checkin: "1 марта 2023",
      checkout: "8 марта 2023",
    },
    max: 24,
    description: [
      "Флавиев амфитеатр, более известный как Колизей, является самым универсально признанным символом Рима. Вы можете исследовать эту популярную туристическую достопримечательность самостоятельно или воспользоваться услугами гида для получения дополнительной информации и возможности задавать вопросы по ходу.",
      "Несмотря на повреждения от пожара, землетрясений и запустения, а также его превращение в крепость семьи Франджипани и разграбление камня для строительства дворцов, Колизей остается крупнейшим сохранившимся сооружением древнего Рима. Его строительство было начато Веспасианом в 72 году н.э., а восемь лет спустя его сын Тит добавил четвертый этаж. Название Колизей произошло от гигантской статуи Нерона, называемой колоссом после Колосса",
    ],
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/tour-pack-2-425x345.jpg",
    images: [
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img2.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img3.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img4.png",
    ],
    Destination: "Париж",
    departureandreturn: "Международный аэропорт имени Кэт. Мэн",
    departuretime:
      "Пожалуйста, прибудьте к 9:15 утра для отправления в 9:30 утра",
    returntime: "Ориентировочно в 10:30 вечера",
    included: [
      "Бесплатная лучшая экскурсия с гидом",
      "Бесплатные сообщения",
      "Без платы за бронирование",
      "Гарантия лучшей цены",
    ],
    excludes: [
      "Дополнительные услуги",
      "Страховка",
      "Транспорт",
      "Входные билеты",
      "Любые частные расходы",
    ],
  },
  {
    id: 5,
    name: "Карибская кухня",
    place: "Карибы",
    category: ["город", "море", "свадьба"],
    price: 678,
    discount: null,
    children: 100,
    duration: {
    days: 9,
    night: 10,
    },
    month: {
    checkin: "1 мая 2023",
    checkout: "8 мая 2023",
    },
    max: 24,
    description: [
    "Флавиев амфитеатр, более известный как Колизей, является самым универсально признанным символом Рима. Вы можете исследовать эту популярную туристическую достопримечательность самостоятельно или воспользоваться услугами гида для получения дополнительной информации и возможности задавать вопросы по ходу.",
    "Несмотря на повреждения от пожара, землетрясений и запустения, а также его превращение в крепость семьи Франджипани и разграбление камня для строительства дворцов, Колизей остается крупнейшим сохранившимся сооружением древнего Рима. Его строительство было начато Веспасианом в 72 году н.э., а восемь лет спустя его сын Тит добавил четвертый этаж. Название Колизей произошло от гигантской статуи Нерона, называемой колоссом после Колосса",
    ],
    thumbnail: "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/tour-pack-7-425x345.jpg",
    images: [
    "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img2.png",
    "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img3.png",
    "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img4.png",
    ],
    Destination: "Карибы",
    departureandreturn: "Международный аэропорт имени Кэт. Мэн",
    departuretime: "Пожалуйста, прибудьте к 9:15 утра для отправления в 9:30 утра",
    returntime: "Ориентировочно в 10:30 вечера",
    included: [
    "Бесплатная лучшая экскурсия с гидом",
    "Бесплатные сообщения",
    "Без платы за бронирование",
    "Гарантия лучшей цены",
    ],
    excludes: [
    "Дополнительные услуги",
    "Страховка",
    "Транспорт",
    "Входные билеты",
    "Любые частные расходы",
    ],
    },
    {
      id: 9,
      name: "Семейный тур с Дубаем!",
      place: "Ближний Восток",
      category: ["город", "путешествие"],
      price: 960,
      discount: null,
      children: 100,
      duration: {
      days: 4,
      night: 5,
      },
      month: {
      checkin: "1 сентября 2023",
      checkout: "8 сентября 2023",
      },
      max: 30,
      description: [
      "Флавиев амфитеатр, более известный как Колизей, является самым универсально признанным символом Рима. Вы можете исследовать эту популярную туристическую достопримечательность самостоятельно или воспользоваться услугами гида для получения дополнительной информации и возможности задавать вопросы по ходу.",
      "Несмотря на повреждения от пожара, землетрясений и запустения, а также его превращение в крепость семьи Франджипани и разграбление камня для строительства дворцов, Колизей остается крупнейшим сохранившимся сооружением древнего Рима. Его строительство было начато Веспасианом в 72 году н.э., а восемь лет спустя его сын Тит добавил четвертый этаж. Название Колизей произошло от гигантской статуи Нерона, называемой колоссом после Колосса",
      ],
      thumbnail: "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/tour-pack-6-425x345.jpg",
      images: [
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img2.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img3.png",
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-details-img4.png",
      ],
      Destination: "Дубай",
      departureandreturn: "Международный аэропорт имени Кэт. Мэн",
      departuretime: "Пожалуйста, прибудьте к 9:15 утра для отправления в 9:30 утра",
      returntime: "Ориентировочно в 10:30 вечера",
      included: [
      "Бесплатная лучшая экскурсия с гидом",
      "Бесплатные сообщения",
      "Без платы за бронирование",
      "Гарантия лучшей цены",
      ],
      excludes: [
      "Дополнительные услуги",
      "Страховка",
      "Транспорт",
      "Входные билеты",
      "Любые частные расходы",
      ],
      }
];
