export const blogs_ru = [
  {
    id: 1,
    title: "Река Ли, оригинальный пейзаж открытки Китая",
    createdAt: "18 февраля 2023 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-dt8.jpg",
    category: "Приключения",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 2,
    title: "Социальное искусство Захи Хадид, присутствие архитектуры",
    createdAt: "22 декабря 2022 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-dt9.jpg",
    category: "Приключения",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 3,
    title: "Джунгли в Париже: Суровый, ретро горнолыжный курорт Вермонта",
    createdAt: "2 октября 2022 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-dt10.jpg",
    category: "Популярное",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 4,
    title: "День на острове Иль-де-Ре. Становится все горячее.",
    createdAt: "11 марта 2023 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2023/01/blog-dt7.jpg",
    category: "Путешествия",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 5,
    title: "Воспользуйтесь 15% скидкой для бронирования.",
    createdAt: "9 января 2020 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/blog-dt1.jpg",
    category: "Популярное",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 6,
    title: "Как выбрать идеальную качественную палатку для кемпинга.",
    createdAt: "21 октября 2023 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/blog-dt2.jpg",
    category: "Пары",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 7,
    title: "Почему не стоит кататься на слонах во Франции.",
    createdAt: "9 ноября 2020 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/blog-dt5.jpg",
    category: "Семья",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
  {
    id: 8,
    title: "Как выбрать идеальную качественную палатку для кемпинга.",
    createdAt: "15 октября 2021 года",
    thumbnail:
      "https://astrip-wp.b-cdn.net/wp-content/uploads/2022/10/blog-dt3.jpg",
    category: "Путешествия",
    description:
      "Площадь Олимпийского национального парка составляет почти один миллион акров, что делает его одним из крупнейших национальных парков в нижних 48 штатах. Здесь можно найти широкий спектр экосистем с высоким уровнем биоразнообразия. От моховых лесов Хох до психоделических приливных бассейнов пляжа Риальто и удаленных ледниковых вершин, выбор места для ночлега может оказаться непростым.",
    quote:
      "Есть выход из каждой коробки, решение каждой головоломки — нужно лишь найти его.",
    details: [
      "Учитывая, что в парке почти дюжина входных станций, выбор места для вашего базового лагеря становится еще сложнее. Независимо от того, хотите ли вы исследовать скалистое побережье Тихого океана, насладиться лесным купанием в Зале мхов или насладиться звуками бурлящей реки, наши рекомендации по лучшему жилью в Олимпийском национальном парке удовлетворят ваши потребности. Ниже представлены 15 вариантов на Airbnb и Vrbo.",
      "Гейзм в области веб-разработки действительно существует. Хотя это не должно мешать вам начать интеллектуально стимулирующую (и хорошо оплачиваемую) карьеру! Готовность учиться и немного креативного мышления для привязки вашего прошлого опыта много значит.",
    ],
  },
];
